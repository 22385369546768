'use strict';

window.addEventListener('DOMContentLoaded', () => {
  // TIME

  function gettingDate() {
    const presentDate = new Date();
    const dateBlock = document.querySelector(`.header__account-date`);
    const day = presentDate.getDate();
    const months = [
      'Января',
      'Февраля',
      'Марта',
      'Апреля',
      'Мая',
      'Июня',
      'Июля',
      'Августа',
      'Сентября',
      'Октября',
      'Ноября',
      'Декабря',
    ];
    const month = presentDate.getUTCMonth();
    const currentDate = `${day} ${months[month]}`;

    dateBlock.innerHTML = currentDate;
  }

  gettingDate();

  // MENU TOGGLE
  const menu = document.querySelector(`.header__menu`);
  const hamburgerBtn = document.querySelector(`.header__hamburger`);

  hamburgerBtn.addEventListener(`click`, () => {
    menu.classList.toggle(`header__menu-active`);
  });

  // MENU TOGGLE PRO

  const showBtn = document.querySelector(`.header__pro-btn`);
  const proBlock = document.querySelector(`.header__pro-try`);
  const closeBtn = document.querySelector(`.header__pro-close-btn`);

  showBtn.addEventListener(`click`, () => {
    showBtn.classList.add(`header__pro-btn-hide`);
    proBlock.classList.add(`header__pro-try-show`);
  });

  closeBtn.addEventListener('click', () => {
    showBtn.classList.remove(`header__pro-btn-hide`);
    proBlock.classList.remove(`header__pro-try-show`);
  });
  const listItem = document.querySelectorAll(`.header__menu-item`);
  const parantItem = document.querySelector(`.header__menu-list`);
  const itemImg = document.querySelectorAll(`.header__menu-img`);
  const itemLink = document.querySelectorAll(`.header__menu-link`);

  function selectItem(i = 0) {
    listItem[i].classList.add(`header__menu-item-active`);
    itemImg[i].src = `./img/menu/menu_${i + 1}_active.svg`;
    itemImg[i].classList.add(`header__menu-img-active`);
  }

  function closeStyle(i = 0) {
    listItem[i].classList.remove(`header__menu-item-active`);
    itemImg[i].src = `./img/menu/menu_${i + 1}.svg`;
    itemImg[i].classList.remove(`header__menu-img-active`);
  }

  parantItem.addEventListener('click', function (event) {
    const target = event.target;
    if (target && target.classList.contains('header__menu-link')) {
      listItem.forEach((item, i) => {
        closeStyle(i);
        if (target == itemLink[i]) {
          menu.classList.remove(`header__menu-active`);
          selectItem(i);
        }
      });
    }
  });

  // HEADER TOGGLE PRO

  const proBtn = document.querySelector(`.header__account-btn`);
  const headerProBlock = document.querySelector(`.header__account-pro`);

  proBtn.addEventListener(`click`, (e) => {
    proBtn.classList.toggle(`header__account-btn-active`);
    headerProBlock.classList.toggle(`header__account-pro-active`);
  });

  // HEADER TOGGLE ACCOUNT

  const accountBtn = document.querySelector(`.header__account-prof`);
  const accountBlock = document.querySelector(`.header__account-menu`);

  accountBtn.addEventListener(`click`, () => {
    accountBlock.classList.toggle(`header__account-menu-active`);
  });

  // POPUP CITIES
  function popupForm(selector, titleSelector, labelSelector, contenrSelector) {
    const selectSingle = document.querySelector(selector);
    const selectSingleTitle = selectSingle.querySelector(titleSelector);
    const selectSingleLabels = selectSingle.querySelectorAll(labelSelector);
    const selectContent = document.querySelector(contenrSelector);

    selectSingleTitle.addEventListener('click', () => {
      if ('active' === selectSingle.getAttribute('data-state')) {
        selectSingle.setAttribute('data-state', '');
        selectContent.style.display = 'none';
      } else {
        selectSingle.setAttribute('data-state', 'active');
        selectContent.style.display = 'flex';
      }
    });

    for (let i = 0; i < selectSingleLabels.length; i++) {
      selectSingleLabels[i].addEventListener('click', (evt) => {
        selectContent.style.display = 'none';
        selectSingleTitle.textContent = evt.target.textContent;
        selectSingle.setAttribute('data-state', '');
      });
    }
  }
  popupForm(
    '.setting__form-select',
    '.setting__form-select-title',
    '.setting__form-select-label',
    `.setting__form-select-content`,
  );

  popupForm(
    '.setting__form-me',
    '.setting__form-me-title',
    '.setting__form-me-label',
    `.setting__form-me-content`,
  );

  // VALIDATION

  const errorName = document.querySelector(`.name__error`);
  const errorSurname = document.querySelector(`.surname__error`);
  const errorEmail = document.querySelector(`.email__error`);
  const pass = document.querySelector(`.pass__error`);
  const newPass = document.querySelector(`.newPass__error`);
  const repNewPass = document.querySelector(`.repNewPass__error`);

  const form = document.querySelector(`.setting__form`);
  const formFields = document.querySelectorAll(`.form_field`);
  const inputName = document.querySelector(`#name`);
  const inputSurame = document.querySelector(`#surname`);
  const inputEmail = document.querySelector(`#email`);
  const inputCurrentPass = document.querySelector(`#current_password`);
  const inputNewPass = document.querySelector(`#new_password`);
  const inputRepeatedNewPass = document.querySelector(`#repeated_new_password`);
  const cityField = document.querySelector(`.setting__form-select-title`);
  const myField = document.querySelector(`.setting__form-me-title`);

  form.addEventListener(`submit`, (e) => {
    e.preventDefault();

    for (let i = 0; i < formFields.length; i++) {
      if (!formFields[i].value) {
        formFields[i].classList.add(`error`);
      } else {
        formFields[i].classList.remove(`error`);
      }
    }

    if (myField.textContent === ``) {
      myField.classList.add(`error`);
      myField.style.cssText = `
      background-image: none;
      `;
    } else {
      myField.classList.remove(`error`);
      myField.style.cssText = `
      background-image: url('../img/icons/tick.svg');
      background-position: 330px center;
      background-repeat: no-repeat;
      `;
    }

    if (cityField.textContent === ``) {
      cityField.classList.add(`error`);
      cityField.style.cssText = `
      background-image: none;
      `;
    } else {
      cityField.classList.remove(`error`);
      cityField.style.cssText = `
      background-image: url('../img/icons/tick.svg');
      background-position: 330px center;
      background-repeat: no-repeat;
      `;
    }

    if (inputNewPass.value !== inputRepeatedNewPass.value) {
      inputNewPass.classList.add(`error`);
      inputRepeatedNewPass.classList.add(`error`);
      inputNewPass.style.cssText = `
      background-image: none;
      `;
      inputRepeatedNewPass.style.cssText = `
      background-image: none;
      `;
      newPass.style.display = `block`;
      repNewPass.style.display = `block`;
    } else {
      newPass.style.display = `none`;
      repNewPass.style.display = `none`;
      inputNewPass.classList.remove(`error`);
      inputRepeatedNewPass.classList.remove(`error`);
      inputNewPass.style.cssText = `
      background-image: url('../img/icons/tick.svg');
      background-position: 370px center;
      background-repeat: no-repeat;
      `;
      inputRepeatedNewPass.style.cssText = `
      background-image: url('../img/icons/tick.svg');
      background-position: 370px center;
      background-repeat: no-repeat;
      `;
    }

    if (/^[a-zA-Zа-яА-Я'][a-zA-Zа-яА-Я-' ]+[a-zA-Zа-яА-Я']?$/giu.test(inputName.value)) {
      inputName.classList.remove(`error`);
      inputName.style.cssText = `
      background-image: url('../img/icons/tick.svg');
      background-position: 370px center;
      background-repeat: no-repeat;
      `;
      errorName.style.display = `none`;
    } else {
      errorName.style.display = `block`;
      inputName.classList.add(`error`);
      inputName.style.cssText = `
      background-image: none;
      `;
    }

    if (/^[a-zA-Zа-яА-Я'][a-zA-Zа-яА-Я-' ]+[a-zA-Zа-яА-Я']?$/giu.test(inputSurame.value)) {
      inputSurame.classList.remove(`error`);
      inputSurame.style.cssText = `
      background-image: url('../img/icons/tick.svg');
      background-position: 370px center;
      background-repeat: no-repeat;
      `;
      errorSurname.style.display = `none`;
    } else {
      errorSurname.style.display = `block`;
      inputSurame.classList.add(`error`);
      inputSurame.style.cssText = `
      background-image: none;
      `;
    }

    if (
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi.test(
        inputEmail.value,
      )
    ) {
      inputEmail.classList.remove(`error`);
      inputEmail.style.cssText = `
      background-image: url('../img/icons/tick.svg');
      background-position: 370px center;
      background-repeat: no-repeat;
      `;
      errorEmail.style.display = `none`;
    } else {
      errorEmail.style.display = `block`;
      inputEmail.classList.add(`error`);
      inputEmail.style.cssText = `
      background-image: none;
      `;
    }

    if (
      /([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+)/.test(
        inputCurrentPass.value,
      )
    ) {
      inputCurrentPass.classList.remove(`error`);
      inputCurrentPass.style.cssText = `
      background-image: url('../img/icons/tick.svg');
      background-position: 370px center;
      background-repeat: no-repeat;
      `;
      pass.style.display = `none`;
    } else {
      pass.style.display = `block`;
      inputCurrentPass.classList.add(`error`);
      inputCurrentPass.style.cssText = `
      background-image: none;
      `;
    }

    if (
      /([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+)/.test(
        inputNewPass.value,
      )
    ) {
      inputNewPass.classList.remove(`error`);
      inputNewPass.style.cssText = `
      background-image: url('../img/icons/tick.svg');
      background-position: 370px center;
      background-repeat: no-repeat;
      `;
    } else {
      inputNewPass.classList.add(`error`);
      inputNewPass.style.cssText = `
      background-image: none;
      `;
    }

    if (
      /([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+)/.test(
        inputRepeatedNewPass.value,
      )
    ) {
      inputRepeatedNewPass.classList.remove(`error`);
      inputRepeatedNewPass.style.cssText = `
      background-image: url('../img/icons/tick.svg');
      background-position: 370px center;
      background-repeat: no-repeat;
      `;
    } else {
      inputRepeatedNewPass.classList.add(`error`);
      inputRepeatedNewPass.style.cssText = `
      background-image: none;
      `;
    }
  });

  // DELETE ACC
  const deleteBtn = document.querySelector(`.setting__delete-btn`);
  const closeModal = document.querySelector(`.modal__header-img`);
  const modal = document.querySelector(`.modal`);
  const modalArea = document.querySelector(`.modal__area`);

  deleteBtn.addEventListener(`click`, () => {
    modal.classList.add(`modal-active`);
  });

  closeModal.addEventListener(`click`, () => {
    modal.classList.remove(`modal-active`);
  });

  modal.addEventListener(`click`, (e) => {
    if (!e.composedPath().includes(modalArea)) {
      modal.classList.remove(`modal-active`);
    }
  });

  // TABS

  const tabs = document.querySelectorAll(`.setting__links-btn`);
  const tabsContent = document.querySelectorAll(`.setting__item`);
  const tabsParant = document.querySelector(`.setting__items`);

  function hideTabContent() {
    tabsContent.forEach((content) => {
      content.classList.add(`setting__item-hide`);
      content.classList.remove(`setting__item-show`);
    });

    tabs.forEach((tab) => {
      tab.classList.remove(`setting__links-btn-active`);
    });
  }

  function showTabContent(i = 0) {
    tabsContent[i].classList.add(`setting__item-show`);
    tabsContent[i].classList.remove(`setting__item-hide`);
    tabs[i].classList.add(`setting__links-btn-active`);
  }

  hideTabContent();
  showTabContent();

  tabsParant.addEventListener(`click`, (e) => {
    if (e.target && e.target.classList.contains(`setting__links-btn`)) {
      tabs.forEach((tab, index) => {
        if (e.target == tab) {
          hideTabContent();
          showTabContent(index);
        }
      });
    }
  });
});
